import { styled, css, type DefaultTheme } from 'styled-components'
import { onMediumScreen } from '../../elements/Theme/styled.utils'

export const _VARIANTS = {
  primary: 'primary',
  underline: 'underline',
}

export const _SIZES = {
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
}

interface SizesStylingProps {
  $size: string
}

function sizesStyling({ $size }: SizesStylingProps) {
  switch ($size) {
    case _SIZES.sm:
      return css`
        height: 36px;
      `
    case _SIZES.md:
      return css`
        height: 40px;
      `
    case _SIZES.lg:
      return css`
        height: 52px;
      `
    case _SIZES.xl:
      return css`
        height: 60px;
      `
  }
}

export const Container = styled.button<SizesStylingProps>`
  ${sizesStyling};

  // Layout
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;

  // Appearance
  background: transparent;
  border: 1px solid transparent;
  user-select: none;
  outline: 0;
`

interface TextSizesStylingProps {
  $size: string
}

function textSizesStyling({ $size }: TextSizesStylingProps) {
  switch ($size) {
    case _SIZES.sm:
      return css`
        font-size: 16px;
        padding: 12px 0;
      `
    case _SIZES.md:
      return css`
        font-size: 16px;
        padding: 12px 0;
      `
    case _SIZES.lg:
      return css`
        font-size: 18px;
        padding: 11px 0;
      `
    case _SIZES.xl:
      return css`
        font-size: 20px;
        padding: 10px 0;
      `
  }
}

interface TextVariantStylingProps {
  $variant: string
  $color?: string
  $hoverColor?: string
  theme: DefaultTheme
}

function textVariantStyling({
  $variant,
  $color,
  $hoverColor,
  theme,
}: TextVariantStylingProps) {
  switch ($variant) {
    case _VARIANTS.primary:
      return css`
        color: ${$color || theme.colors.primary};

        &:hover {
          color: ${$hoverColor || theme.colors.primaryAccent};
          text-decoration-line: underline;
        }
      `
    case _VARIANTS.underline:
      return css`
        color: ${$color || theme.colors.grayDark};
        text-decoration-line: underline;

        &:hover {
          color: ${$hoverColor || theme.colors.secondaryDark};
        }
      `
  }
}

export const Text = styled.span<
  TextSizesStylingProps & TextVariantStylingProps
>`
  ${textSizesStyling};
  ${textVariantStyling};
  cursor: pointer;
  font-weight: 700;
  transition: all 0.2s;

  ${onMediumScreen()} {
    padding: unset;
  }
}`
